.footer {
  background-color: rgba(255, 255, 255, 0.5);
  /* backg: #4a4521; */
  backdrop-filter: blur(10px); /* Applies the blur effect */
  color: white;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: space-around;
}
.footer_title {
  margin: 0px;
  color: #4a4521;
  text-decoration: none;
  font-size: 14px;
}
@media (max-width: 440px) {
  .footer {
    flex-direction: column;
    position: initial;
  }
  .footer_title {
    margin: 2px;
  }
  .footer_end {
    font-size: 24px; /* Reduced for mobile */
  }
}
